import { Dictionary } from '@reduxjs/toolkit'
import omit from 'lodash/fp/omit'
import md5 from 'md5'
import { window } from '@mtsbank/ui-kit'
import { SPA_SBP_PATH, SPA_SBP_RESULT_PATH } from '@root/constants'

import { statDataLoader } from './StatDataLoader'
import { authHelper } from '../authHelper/AuthHelper'

// import { store as reduxStore } from '@store/index'

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
    // dataLayer: Array<unknown>
  }
}

export const emptyEvent = {
  event: null,
  eventCategory: null,
  eventAction: null,
  eventLabel: null,
  eventValue: null,
  userId: null,
  guId: null,
  userAuth: null,
  abonent: null,
  screenName: null,
  eventContent: null,
  eventContext: null,
  buttonLocation: null,
  filterName: null,
  pageType: null,
  actionGroup: null,
  productName: null,
  productId: null,
  numberType: null,
  accountType: null,
  grclientId: null,
  touchPoint: 'web',
  bannerName: null,
  bannerId: null,
  regionId: null,
  region: null,
  prCat: null,
  formId: null,
  CD1: null,
  CD2: null,
  CD3: null,
  CD4: null,
  CD5: null,
  CD6: null,
  CD7: null,
  CD8: null,
  customParams: null,
}

const emptyEventNCPK = {
  event: null,
  eventCategory: null,
  eventAction: null,
  eventLabel: null,
  eventValue: null,
  userId: null,
  grclientId: null,
  grId: null,
  userAuth: null,
  abonent: null,
  screenName: null,
  eventContent: null,
  eventContext: null,
  buttonLocation: null,
  filterName: null,
  pageType: null,
  actionGroup: null,
  productName: null,
  productId: null,
  numberType: null,
  accountType: null,
  touchPoint: 'web',
  bannerName: null,
  bannerId: null,
  regionId: null,
  region: null,
}

export type EventType = Partial<typeof emptyEvent>

export const events = {
  mtsLogin: 'mtsLogin',
  mtsGosU: 'mtsGosU',
  mtsEvent: 'mtsEvent',
  mtsPageview: 'mtsPageview',
  mtsAdv: 'mtsAdv',
  mtsChek: 'mtsChek',
  mtsNcpk: 'mtsNcpk',
  mtsSchet: 'mtsSchet',
  mtsСС: 'mtsСС',
  mtsPP: 'mtsPP',
  mtsPerevody: 'mtsPerevody',
  mtsPremiumPotencial: 'mtsPremiumPotencial',

  vntMain: 'vntMain',
  vntVsego: 'vntVsego',
  vntKosh: 'vntKosh',
  vntIzbr: 'vntIzbr',
  vntTelef: 'vntTelef',
  vntCash: 'vntCash',
  vntKart: 'vntKart',
  vntKred: 'vntKred',
  vntDeng: 'vntDeng',
  vntVklad: 'vntVklad',
  vntSchet: 'vntSchet',
  vntShabl: 'vntShabl',

  mtsCross: 'mtsCross',
  mtsPaymentSpa: 'mtsPaymentSpa',
  mtsMassInc: 'mtsMassInc',
  mtsPasUpd: 'mtsPasUpd',
  mtsPlatezhiPerevody: 'mtsPlatezhiPerevody',
  mtsOnboarding: 'mtsOnboarding',
} as const

export const eventCategories = {
  avtorizaciya: 'avtorizaciya',
  faq: 'faq',
  app: 'app',

  shablonyIAvtoplatezhy: 'shablony_i_avtoplatezhy',
  scheta: 'scheta',
  vklady: 'vklady',
  kredity: 'kredity',
  dengiNaPokupku: 'dengi_na_pokupku',
  karty: 'karty',
  mtsCashback: 'mts_cashback',
  moiTelefon: 'moi_telefon',
  izbrannoe: 'izbrannoe',
  moiKoshelek: 'moi_koshelek',
  vsegoSredstv: 'vsego_sredstv',
  main: 'main',
  platezhi: 'platezhi',
  chek: 'chek',
  advertising: 'advertising',
  perevody: 'perevody',
  error: 'error',
  ncpkForma: 'ncpk_forma',

  glavnayaPaymentsMenu: 'glavnaya_payments_menu',
  glavnayaPaymentsVypodauscheeMenu: 'glavnaya_payments_vypodauschee_menu',
  glavnayaPayments: 'glavnaya_payments',

  glavnayaPaymentsAz: 'glavnaya_payments_az',
  glavnayaVidzhetMneOdobreno: 'glavnaya_vidzhet_mne_odobreno',
  glavnayaVidzhetMoiTelefon: 'glavnaya_vidzhet_moi_telefon',
  glavnayaVidzhetKarty: 'glavnaya_vidzhet_karty',
  glavnayaVidzhetScheta: 'glavnaya_vidzhet_scheta',
  glavnayaVidzhetKredity: 'glavnaya_vidzhet_kredity',
  glavnayaVidzhetDengiNaPokupku: 'glavnaya_dengi_na_pokupku',
  glavnayaVidzhetVklady: 'glavnaya_vidzhet_vklady',
  glavnayaVidzhetPremium: 'vidzhet_premium',
  incidentNotification: 'incident_notification',

  otkrytiePremiumPotencial: 'otkrytie_premium_potencial',
  categories: 'platezhi_i_perevody',
  paymentForm: 'payment_form',
  perevodyZaRubezh: 'perevody_za_rubezh',

  paymentFoto: 'payment_foto',
  paymentCheckbox: 'payment_checkbox',
  paymentDannye: 'payment_dannye',
  paymentGotovo: 'payment_gotovo',
  paymentError2: 'payment_error2',
  netKategorii: 'net_kategorii',
  kreditnyeKarty: 'kreditnye_karty',
  onboarding: 'onboarding',
} as const

export const eventActions = {
  buttonClick: 'button_click',
  buttonShow: 'button_show',
  buttonView: 'button_view',
  linkClick: 'link_click',
  elementClick: 'element_click',
  elementSwipe: 'element_swipe',
  elementClose: 'element_close',
  appClick: 'app_click',

  switcherSwitch: 'switcher_switch',
  elementChange: 'element_change',
  elementFailed: 'element_failed',

  popupShow: 'popup_show',
  popupView: 'popup_view',
  popupClick: 'popup_click',
  popupClose: 'popup_close',

  cardClick: 'card_click',
  elementShow: 'element_show',
  elementView: 'element_view',

  bannerShow: 'banner_show',
  bannerView: 'banner_view',
  bannerClick: 'banner_click',
  bannerClose: 'banner_close',

  formClick: 'form_clik',

  formShow: 'form_show',
  errorShow: 'error_show',
  errorView: 'error_view',
  confirmed: 'confirmed',
  rejected: 'rejected',
  screenShow: 'screen_show',
  screenHide: 'screen_hide',
  screenLoad: 'screen_load',
  formAdd: 'form_add',
  elementStatus: 'element_status',
  reject: 'reject',
  confirm: 'confirm',
  formConfirmed: 'form_confirmed',
  formRejected: 'form_rejected',

  docAdd: 'doc_add',

  widgetShow: 'widget_show',
  widgetView: 'widget_view',
  widgetClick: 'widget_click',

  stikerShow: 'stiker_show',
  stikerView: 'stiker_view',
  stikerClick: 'stiker_click',
  stikerClose: 'stiker_close',
} as const

export const cardStatuses = {
  kartaZablokirovana: 'karta_zablokirovana ',
  kartaNaPerevypuske: 'karta_na_perevypuske ',
  kartaZakryta: 'karta_zakryta ',
  novayaKartaGotova: 'novaya_karta_gotova',
} as const

export type cardStatusesTypes = typeof cardStatuses[keyof typeof cardStatuses]

export const TemplatesAndAutoPaymentsButtons = {
  sablony: 'sablony',
  avtoplatezhy: 'avtoplatezhy',
  schetaNaOplatu: 'scheta_na_oplatu',
} as const

export type TemplatesAndAutoPaymentsButtonTypes =
  typeof TemplatesAndAutoPaymentsButtons[keyof typeof TemplatesAndAutoPaymentsButtons]

export enum StoreMarkets {
  AppStore = 'APP_STORE',
  GooglePlay = 'google_play',
  AppGallery = 'app_gallery',
}

export const eventLabels = {
  voiti: 'voiti',
  vopros: 'vopros',
  mts: 'mtsbank_ru',
  appStore: StoreMarkets.AppStore,
  googlePlay: StoreMarkets.GooglePlay,
  appGallery: StoreMarkets.AppGallery,

  perevodyIPlatezhi: 'perevody_i_platezhi',
  pokazat: 'pokazat',
  skryt: 'skryt',
  oshibkaZagruzkiBalansa: 'oshibka_zagruzki_balansa',
  oshibka: 'oshibka',
  popolnit: 'popolnit',
  cashback: 'cashback',
  perezagruzit: 'perezagruzit',
  otkrytNovyiProdukt: 'otkryt_novyi_produkt',

  popolnenieScheta: 'popolnenie_scheta',
  dalee: 'dalee',
  nomerTelefona: 'nomer_telefona',
  nomerLicevogoScheta: 'nomer_licevogo_scheta',
  nomerKarty: 'nomer_karty',
  srokDeistviyaKarty: 'srok_deistviya_karty',
  cvv: 'cvv',
  summaPlatezha: 'summa_platezha',
  podskazka: 'podskazka',
  sposobOplaty: 'sposob_oplaty',
  razvodyaschayaStranica: 'razvodyaschaya_stranica',

  oplatit: 'oplatit',
  oplata: 'oplata',

  akciya: 'akciya',
  perezagruzka: 'perezagruzka',
  checkbox: 'checkbox',
  email: 'email',
  oshibkaZagruzki: 'oshibka_zagruzki',
  oformitKartu: 'oformit_kartu',
  naGlavnuu: 'na_glavnuu',
  uspeshnyiPerevod: 'uspeshnyi_perevod',
  prodolzhit: 'prodolzhit',
  proverkaDannyh: 'proverka_dannyh',
  sbp: 'sbp',
  spisatS: 'spisat_s',
  ochistit: 'ochistit',
  otdeleniyaNaKarte: 'otdeleniya_na_karte',
  zakryt: 'zakryt',
  perevestiPoKarte: 'perevesti_po_karte',
  povtorit: 'povtorit',
  perevesti: 'perevesti',
  stikery: 'stikery',
  // for ncpk
  issueDone: 'kredit_uspeshno_oformlen',
  resendSmsCode: 'vyslat_sms_kod_povtorno',
  smsCode: 'sms_kod',
  signContract: 'podpisat_dogovor',
  condition: 'usloviyami',
  sendDocsToEmail: 'otpravit_dogovor_na_pochtu',
  sumInfo: 'informaciya_o_summe_na_ruki',
  downloadPDFLoan: 'skachat_dogovor_kreditovaniya_v_pdf',
  downloadPFDInsurance: 'skachat_dogovor_strahovaniya_v_pdf',
  cancelIssue: 'otmenit_zayavku',
  prepareDocs: 'podgotovit_dokumenty',
  issueSend: 'zayavka_na_kredit_otpravlena',
  stories: 'gotovim_dokumenty_po_kreditu',
  refinReason: 'prichina_refinansirovaniya',
  otherReason: 'ukazhite_cel',
  faForma: 'ncpk_fa_forma',
  payDay: 'data_platezha',
  ncpkForma: 'ncpk_forma',
  sumCredit: 'summa_kredita',
  rasschitat_kredit: 'rasschitat_kredit',
  termCredit: 'srok_kredita',
  goalCredit: 'cel_kredita',
  krestik: 'krestik',
  averageMonthly: 'srednemesyachnyi_dohod',
  ncpk_fa_froma: 'ncpk_fa_froma',
  fio: 'familiya_imya_otchetsvo',
  birthday: 'data_rozhdeniya',
  calendar: 'kalendar',
  gender: 'pol',
  checkPersData: 'usloviya_obrabotki_personalnyh_dannyh',
  checkPFR: 'razreshau_zaprosit_vypisku_licevogo_scheta_iz_Pensionnogo_Fonda',
  allowAdvertising: 'soglasen_na_reklamu',
  checkSms: 'ya_dau_svoe_soglasie_na_uvedomlenie_o_specpredlozheniyah_po_sms_i_drugim_kanalam',
  allowProcessingConditions: 'soglasen_na_rassmotreniye_zayavki',
  processingConditions: 'usloviya_rassmotreniya_zayavki',
  agreementList: 'ekran_soglasiya',
  nazad: 'nazad',
  passport: 'seriya_i_nomer_pasporta',
  dataIssue: 'data_vydachi_pasporta',
  kodDepartment: 'kod_podrazdeleniya',
  kemVydan: 'kem_vydan',
  adressReg: 'adres_registracii',
  matchesAdress: 'adres_prozhivaniya_sovpadaet_s_adresom_registracii',
  adressFact: 'adres_fakticheskogo_prozhivaniya',
  codeWord: 'kodovoe_slovo',
  publicFace: 'ya_imeu_otnoshenie_k_publichnomu_dolzhnostnomu_licu',
  checkboxUSA: 'ya_nalogoplatelschik_ssha',
  resident: 'ya_inostrannyi_nalogovyi_rezident',
  workActivity: 'vid_zanyatosti',
  experienceWork: 'god_nachala_trudovogo_stazha',
  nameOrganization: 'nazvanie_organizacii',
  innOrganization: 'inn_organizacii',
  level: 'uroven_dolzhnosti',
  namePosition: 'polnoe_nazvanie_dolzhnosti',
  adressOrganization: 'adres_organizacii',
  startWork: 'data_priema_na_rabotu',
  phoneWork: 'rabochii_telefon',
  education: 'obrazovanie',
  familyStatus: 'semeinoe_polozhenie',
  dependents: 'kolichestvo_chelovek_na_izhdivenii',
  sendForm: 'otpravit_zayavku',
  contractCredit: 'dogovor_kreditovaniya',
  nopurposeLoan: 'necelevoi_kredit',
  serviceError: 'servis_vremenno_nedostupen',
  networkError: 'setevye_oshibki',
  innIp: 'inn_ip',
  checkboxIp: 'ya_yavlyaus_ip',
  incomeСonfirmMainPage: 'podtverdite_dohod',
  incomeСonfirmModalPage: 'podtverdite_dohod_v1',
  clickNaUrovenOdobreniya: 'povysit_uroven_odobreniya',
  showStiker: 'podtverdite_dohod_onlain',
  clickIncomeConfirm: 'podtverdit_dohod',
  clickNotIncomeConfirm: 'ne_podtverzhdat',
  clickNaPortaleStikerLink: 'na_portale',
  clickNaAppStikerLink: 'v_prilozhenii',
  clickCloseStiker: 'krestik',
  firstStep: 'step_1',
  secondStep: 'step_2',
  thirdStep: 'step_3',
  fourthStep: 'step_4',
  otpCode: 'otp_code',
  rate: 'stavka',
  rateModal: 'modalnoe_okno_vyberite_stavky',
  rateChooseModal: 'modalnoe_okno_vybrat_snizhennuu_stavku',
  ok: 'ponyatno',
  keepRate: 'ostavly_vysokuu_stavku',
  rateCut: 'hochu_snizhennuu_stavku',
  moreSum: 'hochu_summu_bolshe',
  insuranceChangeType: 'umenshit_stavku_i_platezh',
  optionsChangeType: 'upravlyai_kreditom',
  loanCalculator: 'parametry_credita',
  loanPurpose: 'cel_kredita_i_dohod',
  boxServices: 'korobochnye_uslugi',
  organizationData: 'o_rabote',
  chooseRate: 'vyberite_stavku',
  multipolisBox: 'paket_Multipolis',
  multipolisBoxDetails: 'podrobnee_paket_Multipolis',
  cardInsuranceBox: 'paket_Zaschita_kart',
  cardInsuranceBoxDetails: 'podrobnee_paket_Zaschita_kart',
  personalData: 'personalnye_dannye',
  agreementListModal: 'modalnoe_okno_s_soglasiyami',
  allowToAdvertising: 'soglasie_na_reklamy',
  signAgreementListModal: 'popup_podpisat_soglasiya',
  smsSign: 'podpisat_po_sms',
  loader: 'louder',
  resendCode: 'vyslat_kod_povtorno',
  passportData: 'pasportnye_dannye',
  showingStories: 'pokaz_stories',
  closeStories: 'zakritie_stories',
  calcConstructor: 'konstruktor_kredita',
  modularPersonalData: 'personalnye_dannye',
  modularPassportData: 'pasportnye_dannye',
  modularEmploymentData: 'o_rabote',
  modularAdditionalData: 'dop_svedeniya',
  modularFormSent: 'zayavka_otpravlena',
  modularFormRefuse: 'zayavka_otklonena',
  changePhone: 'izmenit_nomer_telefona',
  showRefusal: 'pokaz_prichin',
  backRefusal: 'vernutsya',
  clickShare: 'fiks_prichin',
  modalRefusal: 'modal_otmena_zayavki',
  backRefusalModal: 'vernutsya_modal',
  clickCancelIssueModal: 'otmenit_modal',
  orderCall: 'pozvonite',
  modalCancelIssue: 'otmena_ycpeh',
  modalCancelIssueError: 'otmena_oshibka',

  clickNaChastnimKlientam: 'click_na_chastnim_klientam',
  clickNaBisnesu: 'click_na_bisnesu',
  clickNaVseSaity: 'click_na_vse_saity',
  clickNaGoszakazchikam: 'click_na_goszakazchikam',
  clickNaOKompanii: 'click_na_o_kompanii',
  clickNaPostavschkam: 'click_na_postavschkam',
  clickNaMtsMedia: 'click_na_mts_media',
  clickNaInvestorRelations: 'click_na_investor_relations',
  clickNaMtsBankLogo: 'click_na_mts_bank_logo',
  clickNaMtsGlavnaya: 'click_na_mts_glavnaya',
  clickNaMtsGlavnayaNavBar: 'click_na_mts_glavnaya_navbar',

  otkrylGlavnayaPaymentsAz: 'otkryl_glavnaya_payments_az',
  clickNaNomerProfilya: 'click_na_nomer_profilya',
  clickNaMobSvyaz: 'click_na_mob_svyaz',
  clickNaProfile: 'click_na_profile',
  clickNaVyhod: 'click_na_vyhod',
  clickNaMtsAvtoplatezhi: 'click_na_mts_avtoplatezhi',
  clickNaMtsPlatezhi: 'click_na_mts_platezhi',
  clickNaMtsPlatezhiNavBar: 'click_na_mts_platezhi_navbar',
  clickNaMtsIstoriya: 'click_na_mts_istoriya',
  clickNaMtsIstoriyaNavBar: 'click_na_mts_istoriya_navbar',
  clickNaMtsBankovskieProducty: 'click_na_mts_bankovskie_producty',
  clickNaMtsOffers: 'click_na_mts_offers',
  mneOdobreno: 'mne_odobreno',
  moiTelefon: 'moi_telefon',
  bannerShow: 'banner_show',
  bannerProsmotr: 'banner_prosmotr',
  clickNaBanner: 'click_na_banner',
  kartyVidzhetShow: 'karty_vidzhet_show',
  kartyVidzhetProsmotr: 'karty_vidzhet_prosmotr',
  clickNaKartyVidzhet: 'click_na_karty_vidzhet',
  schetaVidzhetShow: 'scheta_vidzhet_show',
  schetaVidzhetProsmotr: 'scheta_vidzhet_prosmotr',
  clickNaSchetaVidzhet: 'click_na_scheta_vidzhet',
  kredityVidzhetShow: 'kredity_vidzhet_show',
  dengiNaPokupkuVidzhetShow: 'dengi_na_pokupku_vidzhet_show',
  kredityVidzhetProsmotr: 'kredity_vidzhet_prosmotr',
  clickNaKredityVidzhet: 'click_na_kredity_vidzhet',
  clickNaDengiNaPokupkuVidzhet: 'click_na_dengi_na_pokupku_vidzhet',
  vkladyVidzhetShow: 'vklady_vidzhet_show',
  vkladyVidzhetProsmotr: 'vklady_vidzhet_prosmotr',
  clickNaVkladyVidzhet: 'click_na_vklady_vidzhet',
  noviyProductButtonShow: 'noviy_product_button_show',
  noviyProductButtonProsmotr: 'noviy_product_button_prosmotr',
  clickNaNoviyProductButton: 'click_na_noviy_product_button',
  recomendaciiVidzhetShow: 'recomendacii_vidzhet_show',
  recomendaciiVidzhetProsmotr: 'recomendacii_vidzhet_prosmotr',
  clickNaRecomendaciiVidzhet: 'click_na_recomendacii_vidzhet',
  shablonyAvtoplatezhiVidzhetShow: 'shablony_avtoplatezhi_vidzhet_show',
  shablonyAvtoplatezhiVidzhetProsmotr: 'shablony_avtoplatezhi_vidzhet_prosmotr',
  clickNaSchetNaOplatyVidzhet: 'click_na_schet_na_oplaty_vidzhet',
  clickNaShablonyVidzhet: 'click_na_shablony_vidzhet',
  clickNaAvtoplatezhiVidzhet: 'click_na_avtoplatezhi_vidzhet',
  clickNaSozdatNoviyVidzhet: 'click_na_sozdat_noviy_vidzhet',
  keshbekAkciiVidzhet: 'keshbek_akcii_vidzhet',
  keshbekAkciiVidzhetCashback: 'keshbek_akcii_vidzhet_cashback',
  escheVidzhet: 'esche_vidzhet',
  escheVidzhetProsmotr: 'esche_vidzhet_prosmotr',
  clickNaIstoriyaOperaciyVidzhet: 'click_na_istoriya_operaciy_vidzhet',
  clickNaKomisiiILimityVidzhet: 'click_na_komisii_i_limity_vidzhet',
  clickNaSpravkiIVypiskiVidzhet: 'click_na_spravki_i_vypiski_vidzhet',
  clickNaOficyIAtmVidzhet: 'click_na_oficy_i_atm_vidzhet',
  incidentToast: 'incident_toast',

  // passportUpdate
  paymentFotoPasportFoto: 'payment_foto_pasport_foto',
  paymentFotoPasportPropiska: 'payment_foto_pasport_propiska',
  paymentCheckboxObnovleniePasporta: 'payment_checkbox_obnovlenie_pasporta',
  paymentCheckboxObrabotkaDannyh: 'payment_checkbox_obrabotka_dannyh',
  paymentCheckboxUslovia: 'payment_checkbox_uslovia',
  paymentDannyeClickDalee: 'payment_dannye_click_dalee',
  paymentGotovoShow: 'payment_gotovo_show',
  paymentError2Show: 'payment_error2_show',
  paymentError2ClickPovtorit: 'payment_error2_click_povtorit',
  paymentGotovoMtsPageView: 'payment_gotovo_mts_page_view',
  paymentError2MtsPageView: 'payment_error2_mts_page_view',
  paymentFotoMtsPageView: 'payment_foto_mts_page_view',
  paymentDannyeMtsPageView: 'payment_dannye_mts_page_view',
  // deposits zone

  // deposit constructor
  depositSettingsScreen: 'ekran_nastojki_vklada',
  depositSettingsErrorScreen: 'ekran_nastojki_vklada_oshibka',
  depositSettingsNoDepositsScreen: 'ekran_nastojki_vklada_oshibka_net_vklada_s_vibrannimi_parametrami',
  next: 'dalee',
  balancesNotLoaded: 'balansi_ne_zgruzilis',
  promocodeCheckBox: 'promocode_checkbox',
  promocodeApplied: 'promocode_primenen',
  promocodeError: 'promocode_oshibka',
  depositAccountsScreen: 'ekran_vibora_scheta',
  noAccountToTopUp: 'net_schetov_dlya_poplnenia',
  tariffTable: 'mgs_tablitsa',
  success: 'ekran_uspeh',
  otpShown: 'pokaz_otp',
  otpWrongCodeError: 'otp_oshibka_nevernij_kod',
  fantomnyiProdukt: 'fantomnyi_produkt',

  // premium
  premium: 'premium',
  clickNaPremiumPackage: 'premialnoe_obsluzhivanie_kak poluchit_besplatno',
  clickNaPremiumManagerPhone: 'telefon_personalnogo_menedzhera',
  clickNaPremiumManagerEmail: 'email_personalnogo_menedzhera',
  clickNaPremiumPrivilege: 'privilegii_click',
  clickNaPremiumCommission: 'limity_i_komissii_po_karte',
  clickNaPremiumTariff: 'tarify_paketa',
  clickNaPremiumQrCode: 'poluchit_qr_kod',
  clickNaGetPackagePrivilege: 'privilegii_podkluchit',
  premiumPotential: 'otkrytie_premium_potencial',
  clickNaGetPackage: 'stat_premium_klientom',
  widgetPremiumPotential: 'vidzhet_premium_potencial',
  clickNaPremiumTerms: 'oznakomtes_s_usloviyami',
  premiumPotentialScreen: 'razdel_premium',

  tsp: 'tsp',
  zapolneniPoleiFormy: 'zapolneni_polei_formy',
  podtverzhdeniePlatezhaЗDS: 'podtverzhdenie_platezha_ЗDS',
  platezhOtpravlen: 'platezh_otpravlen',
  platezhNeVypolnen: 'platezh_ne_vypolnen',
  ikonkiDeistvii: 'ikonki_deistvii',
  kategoriya: 'kategoriya',
  platezhi: 'platezhi',
  perevody: 'perevody',
  proveritOplaitScheta: 'proverit_i_oplait_scheta',
  shablonyAvtoplatezhi: 'shablony_i_avtoplatezhi',
  // transfer abroad
  zaRubezh: 'za_rubezh',
  formaParametrovPerevoda: 'forma_parametrov_perevoda',
  formaDannyhOtpravitelya: 'forma_dannyh_otpravitelya',
  formaProverkiDannyhOtpravitelya: 'forma_prverki_dannyh',
  formaStatusaPerevoda: 'forma_statusa_perevods',
  authNext: 'avtorizaciya_dalee',
  userAndCountriesLoadingErr: 'oshibka_zagruzki_dannyh',
  paymentInstrument: 'platezhnyi_instrument',
  newCard: 'novaya_karta',
  country: 'strana',
  currency: 'valuta',
  sum: 'summa',
  commissionError: 'oshibka_polucheniya_comissii',
  paymentMethode: 'sposob_perevoda',
  additionalFieldsError: 'oshibka_zagruzki_dopolnitelnyh_polei',
  additionalFields: 'dopolnitelnye_polya',
  back: 'nazad',
  continue: 'prodolzhit',
  dossierSearchError: 'oshibka_poiska_dose',
  dossierCreatingError: 'oshibka_sozdaniya_dose',
  senderForm: 'forma_dannyh_otpravitelya',
  stateForm: 'forma_statusa_perevods',
  receipt: 'chek',
  transferConditions: 'usloviya_perevoda',

  // credit carma
  processingConditions2: 'uslovia_rassmotrenia',
  changeInfo: 'pomenyat_dannye',
  deliveryType: 'sposob_poluchenia',
  delivery: 'dostavka',
  pickup: 'samovyvoz',
  deliveryIssueSent: 'dostavka_oformlena',
  sendIssue: 'oformit_zayavku',
  waitSms: 'ozhidajte_sms',
  receiving: 'poluchenie',

  // credit holidays
  creditHolidaysLending: 'lending_kreditnye_kanikuly',
  chooseCredit: 'vyberite_kredit',
  credit: 'kredit',
  availablePrograms: 'dostupnye_programmy',
  program: 'programma',
  gracePeriod377FZ: 'lgotnyi_period_377-FZ',
  documentName: 'nazvanie_dokumenta',
  startSVOParticipationDate: 'data_nachala_uchastiya_v_SVO',
  activationProgramDate: 'data_aktivacii_programmy',
  term: 'srok',
  gracePeriod353FZ: 'lgotnyi_period_353-FZ',
  contactReason: 'prichina_obrascheniya',
  averageMonthlyIncomeForYear: 'srednemesyachnyi_dohod_za_god',
  incomeFor2Months: 'dohod_za_2_mesyaca',
  documentType: 'tip_dokumenta',
  attachPhoto: 'prikrepite_foto',
  attachFile: 'prikreplennyi_fail',
  sendFormModal: 'modalnoe_okno_otpravit_zayavku',
  send: 'otpravit',
  gracePeriodBlockedModal: 'modalnoe_okno_lgotnyi_period_nedostupen',
  chooseProgram: 'vybrat_programmu',
  cancel: 'otmenit',
  formAccepted: 'zayavka_prinyata',
  creditHolidaysActivationFail: 'ne_smozhem_podkluchit_kanikuly',
  possibleReasons: 'vozmozhnye_prichiny',
  possibleReasonsPopUp: 'pop_up_vozmozhnye_prichiny',
  formSendingFail: 'ne_udalos_otpravit_zayavku',
  serverError: 'oshibka_na_servere',

  // collateral
  firstButton: 'pervaya',
  secondButton: 'vtoraya',
  loadLoansInfo: 'zagruzka',
  noAvailableLoans: 'net_creditov',
  loansListLoaded: 'spisok_creditov',
  loanChecked: 'vibral_credit',
  startInputNumber: 'nomer_avto',
  notFoundAvto: 'ne_nashli_avto',
  vinChecked: 'nazhal_na_vin',
  avtoFound: 'vvel_nomer',
  vinFilled: 'vin_zapolnen',
  startInputVin: 'vin_avto',
  vinError: 'ne_nashli_vin',
  submitForReview: 'otpravit_zayavku',
  submitLoding: 'oformlenie',
  foreReviewSend: 'na_ocenke',
  hundredError: '100_dney',
  foreReviewError: 'cherez_chas',
  zalog: 'zalog',

  // services
  services: 'uslugi',
  clickServices: 'uslugi_upravlenie',
  unavailableServices: 'net_dostupnyh_uslug',
  listServices: 'spisok_uslug',
  service: 'uslugа',
  smartReason: 'umnaya_prichina_otkaza',
  info: 'info',
  rulesToConnect: 'pravila_podklucheniya',
  clickConnect: 'podkluchit',
  modalRules: 'pravila_podklucheniya_modal',
  servicesSettings: 'nastroika_uslug',
  clickSignature: 'podpisat',
  otp: 'otp_kod',
  clickDocument: 'dokument',
  successConnect: 'usluga_podkluchena',
  errorConnect: 'usluga_ne_podkluchena',
  shedule: 'grafik_platezhei',

  // onboarding
  slider: 'slider',
  ekran: 'ekran',
  onboardingLoad: 'zagruzka_stranicy_onbordinga',
  onboardingLoadError: 'oshibka_zagruzki_stranicy_onbordinga',
} as const

export type EventLabelsType = typeof eventLabels

export const screenNames = {
  gosuslugiInfo: '/spa/gosuslugi-info', // Страница Госуслуги не выходя из дома
  newMain: '/spa', // Новая главная

  transferCategories: '/spa/categories?tab=transfers',
  transferAbroad: '/spa/transfer/abroad',

  // pay services
  payPhone: '/spa/pay/phone',
  payServiceId: (serviceId: string) => `/spa/pay/${serviceId}`,
  payResult: '/spa/pay/result',
  transferSBP: '/spa/transfer/sbp',

  // категории
  categoriesService: '/spa/categories',
  categoryServiceId: (categoryId: string) => `/spa/categories/${categoryId}`,

  /** Страница МТС Счет */
  accountsOpen: '/spa/accountsopen',
  accountsOpenOrder: (order: string) => `/spa/accountsopen/order/${order}`,
  // bank product
  bankProducts: '/spa/bank-products',
  bankProductsDetails: (productType: string) => `/spa/bank-products/details/${productType}`,

  // bank product
  offers: '/spa/offers',

  personalOffer: (offerId: string) => `/spa/personal_offer?offerId=${offerId} `,
  // ncpk form

  /** Зона вкладов */

  // Конструктор вкладов
  depositConstructor: '/spa',
  depositConstructorStep: (step: string) => `/spa/deposits/open/${step}`,

  // premium
  premiumMain: '/spa/premium',
  premiumOffer: '/spa/premium/offer-premium',
  premiumService: (serviceId: string) => `/spa/premium/${serviceId}`,

  // passport-update
  passportUpdate: '/spa/passport-update',
} as const

type ScreenNamesTypes = typeof screenNames
export type ScreenNamesValues = ScreenNamesTypes[keyof ScreenNamesTypes]

// todo screenNames по хорошему в константы вынести, а pageConfig в конфиг
export const pageConfig = {
  [SPA_SBP_RESULT_PATH]: {
    screenName: screenNames.transferSBP,
    pageType: screenNames.transferSBP,
  },
  [SPA_SBP_PATH]: {
    screenName: screenNames.transferSBP,
    pageType: screenNames.transferSBP,
  },
  [screenNames.gosuslugiInfo]: {
    screenName: screenNames.gosuslugiInfo,
    pageType: screenNames.gosuslugiInfo,
  },
}

export const eventContents = {
  banner: 'banner',
  vLichnomKabinete: 'v_lichnom_kabinete',
  bezAvtorizacii: 'bez_avtorizacii',
  mneNuzhenFiskalnyiChek: 'mne_nuzhen_fiskalnyi_chek',
  spisatS: 'spisat_s',
  polzunok: 'polzunok',
  step1: 'step_1',
  vruchnuu: 'vruchnuu',
  celKredita: 'cel_kredita',
  step2: 'step_2',
  dataRozhdeniya: 'data_rozhdeniya',
  step3: 'step_3',
  dataVydachiPasporta: 'data_vydachi_pasporta',
  step4: 'step_4',
  vidZanyatosti: 'vid_zanyatosti',
  urovenDolzhnosti: 'uroven_dolzhnosti',
  obrazovanie: 'obrazovanie',
  semeinoePolozhenie: 'semeinoe_polozhenie',
  informacionnayaStranica: 'informacionnaya_stranica',
  obnovleniePersonalnyhDannyh: 'obnovlenie_personalnyh_dannyh',
  EntryPoint: 'EntryPoint',
  formParams: 'forma_parametrov_perevoda',
  senderForm: 'forma_dannyh_otpravitelya',
  checkForm: 'forma_prverki_dannyh',
  scenarioEnter: 'vhod_v_scenarii',
} as const

export const eventContexts = {
  mtsMoney: 'mts_dengi',
  otkrytSchet: 'otkryt_schet',
  oformiteZayavkuNaKredit: 'oformite_zayavku_na_kredit',
  vyOformiliKredit: 'vy_oformili_kredit',
  vhodVScenarii: 'vhod_v_scenarii',
} as const

export const buttonLocations = {
  footer: 'footer',
} as const

export const actionGroups = {
  interactions: 'interactions',
  nonInteractions: 'non_interactions',
  conversions: 'conversions',
} as const

export function sendGmtEvent(
  event: {
    [K in keyof typeof emptyEvent]?: string | Record<string, boolean | string | string[]> | null
  },
  pagesEvent?: boolean
) {
  const { userProfile, userData } = authHelper

  if (!window.dataLayer) {
    window.dataLayer = []
  }

  const baseEvent = pagesEvent
    ? omit(['eventCategory', 'eventAction', 'eventLabel', 'eventValue'], emptyEvent)
    : emptyEvent

  window.dataLayer.push({
    ...baseEvent,
    userAuth: authHelper.isAuthenticated() ? '1' : '0',
    ...(userProfile?.mobilePhone && { ph: md5(userProfile?.mobilePhone) }),
    ...(userData?.rbo_id && { grclientId: userData.rbo_id }),
    ...event,
  })
}

type ScreenNamesKeys = keyof typeof screenNames

export function gtmPagesEvents(event?: { [K in keyof typeof emptyEvent]?: string | null }): {
  [K in ScreenNamesKeys]: (args?) => void
} {
  const keys = Object.keys(screenNames) as Array<ScreenNamesKeys>

  return keys.reduce(
    (acc, page: ScreenNamesKeys) => ({
      ...acc,
      [page]: (...args) => {
        sendGmtEvent(
          {
            event: events.mtsPageview,
            screenName:
              typeof screenNames[page] === 'function'
                ? (screenNames[page] as (args) => string)(args)
                : (screenNames[page] as string),
            actionGroup: actionGroups.nonInteractions,
            ...event,
          },
          true
        )
      },
    }),
    {} as { [K in ScreenNamesKeys]: (args?) => void }
  )
}

/** Отправлям только то что прописанно в pageConfig для осознанного встраивания разметки */
export const sendGmtEventPerPage = (
  eventOptions: { [K in keyof typeof emptyEvent]?: string | null },
  pagesEvent?: boolean,
  pathname?: string
) => {
  const path = pathname ?? window.location.pathname
  const pageParams: Dictionary<Dictionary<string>> = pageConfig[path]

  statDataLoader.push((event: EventType) =>
    sendGmtEvent(
      {
        ...event,
        ...pageParams,
        ...eventOptions,
      },
      pagesEvent
    )
  )
}

export function sendGmtNCPKEvent(event: { [K in keyof typeof emptyEventNCPK]?: string | null }, pagesEvent?: boolean) {
  if (!window.dataLayer) {
    window.dataLayer = []
  }

  const baseEvent = pagesEvent
    ? omit(['eventCategory', 'eventAction', 'eventLabel', 'eventValue'], emptyEventNCPK)
    : emptyEventNCPK

  // may be store.auth.isAuthenticated ? 1 : 0
  window.dataLayer.push({ ...baseEvent, userAuth: localStorage.getItem('access_token') ? '1' : '0', ...event })
}
