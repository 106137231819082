import { IncompleteOrderDtoV2StatusEnum as IncompleteStatusEnum } from '@open-api/ump/dbo-pos-issue'
import { Status } from '@components/NewMain/Credits/types'
import { NextPaymentDtoV2StateEnum as StatusCode } from '@open-api/ump/dbo-loan'
import { StatusIcon } from '@components/NewMain/types'

export const LENGTH_SIDE = 48

export const product = 'MTS.BUY.PS' as const

export const MONTH_ENDINGS_GENITIVE = ['месяц', 'месяца', 'месяцев']

export const creditStatus: Record<Exclude<StatusCode, StatusCode.DEFAULT>, Status> = {
  [StatusCode.EXPIRED]: {
    icon: StatusIcon.WARNING,
    message: 'Платеж\u00a0просрочен',
  },
  [StatusCode.DEADLINE]: {
    icon: StatusIcon.WARNING,
    message: 'Недостаточно\u00a0средств',
  },
  [StatusCode.TOCLOSE]: {
    icon: StatusIcon.SUCCESS,
    message: 'К\u00a0закрытию',
  },
  [StatusCode.DONE]: {
    icon: StatusIcon.SUCCESS,
    message: 'Платеж\u00a0внесен',
  },
  [StatusCode.ENOUGH]: {
    icon: StatusIcon.SUCCESS,
    message: 'Достаточно\u00a0средств',
  },
}

export const statuses: Array<IncompleteStatusEnum> = [
  IncompleteStatusEnum.WAITSIGN,
  IncompleteStatusEnum.QUEUED,
  IncompleteStatusEnum.EXPECTIDENTIFICATION,
]

export const TERMINATION_STATUS_CODES = [StatusCode.DEADLINE, StatusCode.EXPIRED]
